export class Password {
  hash: string = '';

  plain: string = '';

  otp: string = '';

  reset: boolean = false;

  constructor(password?: { [key: string]: any }) {
    if (password) {
      this.hash = password['hash'] || '';

      this.plain = password['plain'] || '';

      this.otp = password['otp'] || '';

      if (
        String(password['reset']) === 'true' ||
        String(password['reset']) === 'false'
      ) {
        this.reset = String(password['reset']) === 'true';
      }
    }
  }
}
