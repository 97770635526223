<ion-header>
  <div class="header-wrap">
    <div class="icon-wrap" *ngIf="showBackButton" (click)="goBack()">
      <ion-icon src="/assets/icons/left-arrow-icon.svg"> </ion-icon>
    </div>

    <div class="name-wrap">
      <span class="h4">{{ pageName }}</span>
    </div>
  </div>
</ion-header>
