import { Component, OnInit } from '@angular/core';
import { User } from './shared/models/users/user';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  user: User = new User();

  constructor() {}

  ngOnInit(): void {
    this.user = new User(
      JSON.parse(localStorage.getItem('flashdeck-me') || '{}')
    );
  }
}
