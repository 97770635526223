import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: () =>
      import('./app-pages/landing/landing.module').then(
        (m) => m.LandingPageModule
      ),
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./app-pages/login/login.module').then((m) => m.LoginPageModule),
  },

  {
    path: 'registration',
    loadChildren: () =>
      import('./app-pages/registration/registration.module').then(
        (m) => m.RegistrationPageModule
      ),
  },

  {
    path: 'verify-phone',
    loadChildren: () =>
      import('./app-pages/verify-phone/verify-phone.module').then(
        (m) => m.VerifyPhonePageModule
      ),
  },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./app-pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },

  {
    path: 'library',
    loadChildren: () =>
      import('./app-pages/library/library.module').then(
        (m) => m.LibraryPageModule
      ),
  },

  {
    path: 'profile-setting',
    loadChildren: () =>
      import('./app-pages/profile-setting/profile-setting.module').then(
        (m) => m.ProfileSettingPageModule
      ),
  },

  {
    path: 'store',
    loadChildren: () =>
      import('./app-pages/store/store.module').then((m) => m.StorePageModule),
  },

  {
    path: 'cart',
    loadChildren: () =>
      import('./app-pages/cart/cart.module').then((m) => m.CartPageModule),
  },

  {
    path: 'profile-details',
    loadChildren: () =>
      import('./app-pages/profile-details/profile-details.module').then(
        (m) => m.ProfileDetailsPageModule
      ),
  },

  {
    path: '',
    redirectTo: 'library',
    pathMatch: 'full',
  },

  {
    path: '**',
    redirectTo: 'library',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
