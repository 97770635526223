export class Image {
  url: string = '';

  size?: number = 0;

  type?: string = '';

  constructor(image?: { [key: string]: any }) {
    if (image) {
      this.url = image['url'] || '';

      // if (this.url) {
      //   const urlMatches = this.url.match(/https?/g);
      //   if (urlMatches?.length > 1) {
      //     const splitPoint = urlMatches[urlMatches.length - 1];
      //     const splitArray = this.url.split(splitPoint);

      //     this.url = splitPoint + splitArray[splitArray.length - 1];
      //   }
      // }

      this.type = image['type'] || '';

      if (!isNaN((image['size'] = parseInt(image['size'])))) {
        this.size = image['size'];
      }
    }
  }
}
