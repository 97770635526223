import { LoginUserDTO } from '../../dtos/users/login-user-dto/login-user-dto';
import { ProfileImageDTO } from '../../dtos/users/profile-image-dto/profile-image-dto';
import { RegisterUserDTO } from '../../dtos/users/register-user-dto/register-user-dto';
import { ResetPasswordDTO } from '../../dtos/users/reset-password-dto/reset-password-dto';
import { SendVerificationEmailDTO } from '../../dtos/users/send-verification-email-dto/send-verification-email-dto';
import { SendVerificationMessageDTO } from '../../dtos/users/send-verification-message-dto/send-verification-message-dto';
import { UpdateEmailDTO } from '../../dtos/users/update-email-dto/update-email-dto';
import { UpdatePasswordDTO } from '../../dtos/users/update-password-dto/update-password-dto';
import { UpdatePhoneDTO } from '../../dtos/users/update-phone-dto/update-phone-dto';
import { UpdateRoleDTO } from '../../dtos/users/update-role-dto/update-role-dto';
import { UpdateUserDTO } from '../../dtos/users/update-user-dto/update-user-dto';
import { VerifyEmailDTO } from '../../dtos/users/verify-email-dto/verify-email-dto';
import { VerifyPhoneDTO } from '../../dtos/users/verify-phone-dto/verify-phone-dto';
import { USER_GENDERS, USER_ROLES } from '../../settings/app-settings';
import { Image } from '../image/image';
import { Token } from '../tokens/token';
import { Email } from './email/email';
import { Password } from './password/password';
import { Phone } from './phone/phone';

export class User {
  userId: string = '';

  name: string = '';

  bio?: string = '';

  profileImage: Image = new Image();

  email: Email = new Email();

  phone: Phone = new Phone();

  gender?: USER_GENDERS;

  password: Password = new Password();

  role: USER_ROLES = 'CANDIDATE';

  examIds?: string[] = [];

  lastLogin?: number = 0;

  tokens?: Token[] = [];

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(user?: { [key: string]: any }) {
    if (user) {
      this.userId = user['userId'] || user['_id'] || '';

      this.name = user['name'] || '';

      this.bio = user['bio'] || '';

      if (user['profileImage']) {
        this.profileImage = new Image(user['profileImage']);
      }

      if (user['email']) {
        this.email = new Email(user['email']);
      }

      if (user['phone']) {
        this.phone = new Phone(user['phone']);
      }

      this.gender = user['gender'] || '';

      if (user['password']) {
        this.password = new Password(user['password']);
      }

      this.role = user['role'] || 'CANDIDATE';

      if (user['examIds']) {
        for (let examId of user['examIds']) {
          this.examIds?.push(examId);
        }
      }

      if (!isNaN((user['lastLogin'] = parseInt(user['lastLogin'])))) {
        this.lastLogin = user['lastLogin'];
      }

      this.tokens = [];
      if (user['tokens']) {
        for (let token of user['tokens']) {
          this.tokens.push(new Token(token));
        }
      }

      this.timestamp = user['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toRegisterDTO(): RegisterUserDTO {
    return {
      name: this.name,
      phone: this.phone,
      email: this.email,
      gender: this.gender,
      role: this.role,
      password: this.password.plain,
      examIds: this.examIds,
    };
  }

  toUpdateDTO(): UpdateUserDTO {
    return {
      userId: this.userId,
      name: this.name,
      bio: this.bio,
      gender: this.gender,
      examIds: this.examIds,
    };
  }

  toLoginDTO(): LoginUserDTO {
    return {
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
      password: this.password.plain,
    };
  }

  toUpdateRoleDTO(): UpdateRoleDTO {
    return {
      userId: this.userId,
      role: this.role,
    };
  }

  toResetPasswordDTO(): ResetPasswordDTO {
    return {
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
    };
  }

  toUpdatePasswordDTO(): UpdatePasswordDTO {
    return {
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
      newPassword: this.password.plain,
      currentPassword: this.password.plain,
      passwordOTP: this.password.otp,
    };
  }

  toVerifyEmailDTO(): VerifyEmailDTO {
    return {
      emailValue: this.email?.value,
      emailOTP: this.email?.otp,
    };
  }

  toVerifyPhoneDTO(): VerifyPhoneDTO {
    return {
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
      phoneOTP: this.phone.otp,
    };
  }

  toUpdateEmailDTO(): UpdateEmailDTO {
    return {
      userId: this.userId,
      emailValue: this.email?.value,
    };
  }

  toSendVerificationEmailDTO(): SendVerificationEmailDTO {
    return {
      emailValue: this.email.value,
    };
  }

  toSendVerificationMessageDTO(): SendVerificationMessageDTO {
    return {
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
    };
  }

  toUpdatePhoneDTO(): UpdatePhoneDTO {
    return {
      userId: this.userId,
      countryCode: this.phone.countryCode,
      phoneNumber: this.phone.number,
    };
  }

  toProfileImageDTO(): ProfileImageDTO {
    return {
      userId: this.userId,
      profileImage: this.profileImage,
    };
  }
}
