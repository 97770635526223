import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  librarySelected: string = 'library-icon.svg';
  storeSelected: string = 'store-icon.svg';
  cartSelected: string = 'cart-icon.svg';
  settingsSelected: string = 'profile-icon.svg';

  constructor(private readonly router: Router) {}

  ngOnInit() {
    if (this.router.url === '/library') {
      this.librarySelected = 'library-selected-icon.svg';
    }

    if (this.router.url === '/store') {
      this.storeSelected = 'store-selected-icon.svg';
    }

    if (this.router.url === '/cart') {
      this.cartSelected = 'cart-selected-icon.svg';
    }

    if (this.router.url === '/profile-setting') {
      this.settingsSelected = 'profile-selected-icon.svg';
    }
  }
}
