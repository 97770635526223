<ion-footer>
  <div class="footer-wrap">
    <div
      class="menu-wrap"
      routerLink="/library"
      routerDirection="root"
      routerLinkActive="active"
    >
      <div class="selected-wrap"></div>

      <div>
        <ion-icon src="/assets/icons/{{ librarySelected }}"></ion-icon>

        <ion-label class="caption">Library</ion-label>
      </div>
    </div>

    <div
      class="menu-wrap"
      routerLink="/store"
      routerDirection="root"
      routerLinkActive="active"
    >
      <div class="selected-wrap"></div>

      <div>
        <ion-icon src="/assets/icons/{{ storeSelected }}"></ion-icon>

        <ion-label class="caption">Store</ion-label>
      </div>
    </div>

    <div
      class="menu-wrap"
      routerLink="/cart"
      routerDirection="root"
      routerLinkActive="active"
    >
      <div class="selected-wrap"></div>

      <div>
        <ion-icon src="/assets/icons/{{ cartSelected }}"></ion-icon>

        <ion-label class="caption">Cart</ion-label>
      </div>
    </div>

    <div
      class="menu-wrap"
      routerLink="/profile-setting"
      routerDirection="root"
      routerLinkActive="active"
    >
      <div class="selected-wrap"></div>

      <div>
        <ion-icon src="/assets/icons/{{ settingsSelected }}"></ion-icon>

        <ion-label class="caption">Settings</ion-label>
      </div>
    </div>
  </div>
</ion-footer>
