import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { NavComponent } from './components/nav/nav.component';

@NgModule({
  declarations: [TimeAgoPipe, FileSizePipe, HeaderComponent, NavComponent],
  imports: [CommonModule, FormsModule, IonicModule, RouterModule],
  exports: [HeaderComponent, NavComponent],
})
export class SharedModule {}
